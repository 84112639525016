<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';
import Header from '@/Pages/Staff/Flexers/Edit/Header.vue';
import Form from '@/Pages/Staff/Flexers/Edit/Form.vue';
import FormSubject from '@/Components/Form/FormSubject.vue';
import FormButton from '@/Components/Button/FormButton.vue';
import { inject } from 'vue';

const route = inject('route');

defineProps({
    flexer: Object,
    company: Object,
    company_address: Object,
    legalFormOptions: Object,
    identificationCardTypeOptions: Object,
    maritalStatusOptions: Object,
    countryOptions: Object,
    otherFlexers: Object,
    blockedBy: Array,
});
</script>

<template>
    <StaffLayout :title="$t('Fl@xr')">
        <Header :flexer="flexer" />
        <Form
            :flexer="flexer"
            :country-options="countryOptions"
            :identification-card-type-options="identificationCardTypeOptions"
            :marital-status-options="maritalStatusOptions"
            :other-flexers="otherFlexers"
        />

        <FormSubject
            :title="$t('Blocked by')"
            class="my-15"
        >
            <div
                v-if="blockedBy.length >0"
                class="divide-y divide-blue/30"
            >
                <template
                    v-for="location in blockedBy"
                    :key="location.id"
                >
                    <div class="flex-between py-2">
                        {{ location.nickname }}
                        <FormButton
                            :data="{ location_id: location.id }"
                            :label="$t('Unblock')"
                            :url="route('staff.flexers.blocks.destroy', flexer.id )"
                            method="delete"
                        />
                    </div>
                </template>
            </div>
            <div
                v-else
                class=" text-blue"
            >
                {{ $t('No blocked locations') }}
            </div>
        </FormSubject>
    </StaffLayout>
</template>
