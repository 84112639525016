<script setup>
/**
 * Useful to display a button that submits a form.
 * Has a build in form which is send to the given url using the given method and includes the given data.
 *
 * See button components for more information on the button itself.
 */
import Button from '@/Components/Button/Button.vue';
import InlineButton from '@/Components/Button/InlineButton.vue';

import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    url: String,
    method: {
        type: String,
        default: 'get',
    },
    data: {
        type: Object,
    },
    label: String,
    disabled: Boolean,
    red: Boolean,
    inline: Boolean,
});

const emits = defineEmits(['onSuccess']);

const form = useForm({});

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        ...(props.data ?? {}),
    })).submit(props.method, props.url, {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => {
            emits('onSuccess');
        },
    });
};
</script>
<template>
    <InlineButton
        v-if="inline"
        :label="label"
        :form="form"
        :red="red || method === 'delete'"
        :disabled="disabled"
        @click="submitForm"
    />
    <Button
        v-else
        :label="label"
        :form="form"
        :red="red || method === 'delete'"
        :disabled="disabled"
        @click="submitForm"
    />
</template>
