<script setup>
/**
 * Useful to display a button in a component.
 * Is a smaller version of the button component.
 */
import { useSlots } from 'vue';
import ReactiveSlot from './Partials/ReactiveSlot.vue';

const props = defineProps({
    label: String,
    form: Object,
    red: Boolean,
    disabled: Boolean,
    is: String,
});

const normal = !props.red;

const slots = useSlots();
</script>
<template>
    <component
        :is="is == 'a' ? 'a' : 'button'"
        :type="is == 'a' ? '' : 'button'"
        class="relative inline-flex min-w-30 items-center rounded px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-700"
        :class="{
            'bg-red-700 text-white hover:bg-red-800 focus:ring-red-600': red,
            'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-400': normal,
            'cursor-progress': form?.processing,
            'cursor-not-allowed': form?.recentlySuccessful,
        }"
        :disabled="disabled"
    >
        <ReactiveSlot :processing="form?.processing ?? false" :recentlySuccessful="form?.recentlySuccessful ?? false">
            <div v-if="!slots.default" class="leading-none">{{ label }}</div>

            <slot></slot>
        </ReactiveSlot>
    </component>
</template>
